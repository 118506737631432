import './product-slider.scss';

import Swiper, { Pagination, Navigation } from 'swiper';

class ProductSlider {
    constructor ($element) {
        this.$productSlider = $element;
        this.$slides = this.$productSlider.querySelectorAll('[data-product-slider="slide"]');
        this.slider = null;
    }

    initialize () {
        if (this.$slides.length > 1) {
            this.initSlider();
        }
    }

    initSlider () {
        Swiper.use([Pagination]);
        Swiper.use([Navigation]);
        this.slider = new Swiper(this.$productSlider, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            initialSlide: 0,
            speed: 1000,
            slideClass: 'product-slider__slider-item',
            wrapperClass: 'product-slider__slider',
            pagination: {
                el: '.product-slider__pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: '.product-slider__button-next',
                prevEl: '.product-slider__button-prev'
            },
            on: {
                slideChangeTransitionStart: () => {
                    const $progressBar = this.$productSlider.querySelector('.slider-pagination');
                    $progressBar.classList.add('is--active');
                },
                slideChangeTransitionEnd: () => {
                    const $progressBar = this.$productSlider.querySelector('.slider-pagination');
                    $progressBar.classList.remove('is--active');
                }
            }
        });
    }
}

export { ProductSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $productSliders = $context.querySelectorAll('[data-product-slider="root"]');
        if ($productSliders.length > 0) {
            $productSliders.forEach((productSlider) => {
                const $productSlider = new ProductSlider(productSlider);
                $productSlider.initialize();
            });
        }
    }
});
